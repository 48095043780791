(function() {
  'use strict';

  angular
    .module('settings')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('settings', {
        url: '/settings',
        views: {
          'mainView': {
            templateUrl: 'settings/settings.tpl.html',
            controller: 'SettingsCtrl',
            controllerAs: 'settings'
          },
          'layoutView': {
            templateUrl: 'partials/layout.html',
          }
        }

      });
  }
}());
